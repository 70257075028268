<template>
  <div class="wrap">
    <Title pageTitle="RESIZE" pageDiscrption="お直しサービス" />
    <div class="container">
      <h1 class="heading-1">体型や好みが変わっても購入毎に修正できる。<br />いつでもベストのTシャツをお届けします</h1>
      <div class="discription">
        <p>
          せっかくオーダーメイドでTシャツを作ったのに体型が変わってしまった。Tシャツを作った時はタイトが好みだったけど、今は少しルーズに着たい。着用している中でやっぱり着丈がもう少し長い方が良かったな。など、時間が経てば体型や好みが変わる事もあります。
        </p>
        <p>
          そのようなお客様の要望に応えるため、当店のオーダーTシャツでは購入毎にTシャツのサイズを修正できるお直しサービスをご用意しております。
        </p>
        <p>
          テイラーTシャツをご購入のお客様は、お客様の型紙を保管しておりますので追加注文時に変更したい箇所をご指示いただければ、無料で修正いたします。
        </p>
        <p>また、カスタマイズTシャツは着丈のみの修正に限りますが、注文時にご指定いただければご希望のサイズで製作いたします。</p>
        <p>お客様にとっていつでもその時のベストなTシャツをお届けいたします。</p>
      </div>
      <img class="chart" src="../assets/img/fix-chart.svg" alt="" />
    </div>
  </div>
</template>

<script>
import Title from '../components/Title.vue'

export default {
  components: { Title },
}
</script>

<style lang="scss" scoped>
.wrap {
  background-image: url('../assets/img/fix-bg.jpg');
  background-size: 110%;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;

  @media screen and (max-width: 767px) {
    background-size: 160%;
  }
}
.discription {
  column-count: 2;
  column-gap: 5rem;

  @media screen and (max-width: 767px) {
    column-count: 1;
  }
}
.chart {
  display: block;
  margin: 8rem auto 0;
  width: 70%;

  @media screen and (max-width: 768px) {
    width: 80%;
    margin: 4rem auto 0;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
    margin: 2rem auto 0;
  }
}
</style>
