<template>
  <div class="title">
    <h2 class="title__category">{{ pageTitle }}</h2>
    <div class="title__line" :style="{ backgroundColor: color }"></div>
    <div class="title__sub">{{ pageDiscrption }}</div>
  </div>
</template>

<script>
export default {
  props: ['pageTitle', 'pageDiscrption', 'color'],
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  padding: 8rem 0 4rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__category {
    font-size: 3rem;
    letter-spacing: 0.6rem;
    font-family: 'objektiv-mk1', sans-serif;

    @media screen and (max-width: 460px) {
      letter-spacing: 0.2rem;
    }
  }

  &__line {
    width: 50px;
    height: 2px;
    background-color: var(--color-dark);
    margin: 3rem 0 4rem;
  }

  &__sub {
    font-size: 13.2px;
    font-weight: 300;
    letter-spacing: 2px;
  }
}
</style>
